import React from "react";
import logoImg from "../assets/images/main-logo.png";
import footer1 from "../assets/images/footer-1.png";
import footer2 from "../assets/images/footer-2.png";

function Footer() {
  return (
    <div className="main-footer py-4 bg-primary">
      <div className="row m-0 justify-content-around">
        <div className="col-9 col-md-6">
          <h6 className="mb-3">Descarga la App</h6>
          <div>
            <a href="https://www.apple.com/la/app-store/" target={"_blank"} rel="noreferrer">
              <img src={footer1} className="footer-img" alt="apple-link" />
            </a>
            <a href="https://play.google.com/store?hl=es" target={"_blank"} rel="noreferrer">
              <img src={footer2} className="footer-img" alt="play-link" />
            </a>
          </div>
        </div>
        <div className="col-2 col-md-3 display-flex justify-content-end">
          <img src={logoImg} className="logo-img" alt="logo-img" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
