import React, { useState } from "react";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import qs from 'qs';
import { sendToLeadbox } from "../api";
import logoImg from "../assets/images/success-logo.png";

function Section1() {
  const [isSubmit, setIsSubmit] = useState(false);
  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string().required().email({ tlds: { allow: false } }),
    phone: Joi.number().required(),
    city: Joi.string().required(),
    contactVia: Joi.string().valid('WhatsApp','Telefono', 'Mail').required(),
    //position: Joi.string().valid('Administrador','Residente').required(),
    //useSoftware: Joi.string().optional(),
    //anyHelp: Joi.string().required(),∫
  });

/*
 <div className="mb-3">
                      <select
                      className={`form-control shadow-none ${ errors.position ? "is-invalid" : "" }`} 
                      name="position" {...register("position")}>
                        <option>Selecciona tu cargo</option>
                        <option value="Administrador">Administrador</option>
                        <option value="Residente">Residente</option>
                      </select>
                    </div>
                    
                    <div className="mb-3">
                    <select
                      className={`form-control shadow-none ${ errors.useSoftware ? "is-invalid" : "" }`} 
                      name="useSoftware" {...register("useSoftware")}>
                        <option value="-">Utilizas algún software</option>
                        <option value="Si">SI</option>
                        <option value="No">NO</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="anyHelp"
                        {...register("anyHelp")}
                        className={`form-control shadow-none ${ errors.anyHelp ? "is-invalid" : "" }`}
                        placeholder="¿Cómo podemos ayudarte?"
                      />
                    </div>
*/
    //position: Joi.string().required(), .valid('admin','student').required()
    //useSoftware: Joi.string().required(),

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = (data) => {
    // createContactUs(data).then(() => {
    //   reset();
    //   setIsSubmit(true);
    // });
    console.log(data);
    data.leadbox_token = '27ee4030-23cc-4071-b4a3-22fdc722836f|ZP0XLMTFp5kNx3T2JnU6T3XtSVhp548QKy5zVCmIZUlmNnBsYsRAHc53wwsEv6HGDPv5009mxTHrzkKhYvtpNvP1tOqQZX2MIRH7';
    console.log(qs.stringify(data));
    sendToLeadbox(qs.stringify(data)).then(() => {
      reset();
      //setIsSubmit(true);
      document.location = "/gracias";
    });

    //setIsSubmit(true);
  };

  return (
    <section id="contáctanos" className={`section_1 p-4 p-md-5 pt-5 ${isSubmit && 'main-success'}`}>
      <div className="row m-0 justify-content-around pb-5">
        { !isSubmit ? (
          <>
            <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
              <h1 className="title1 fs-extraBold">Te acerca a</h1>
              <h1 className="title2 fs-extraBold">tu comunidad</h1>
              <h6 className="mt-4 text-primary">
                <b className="fs-bold">TRIBU</b> de Banco Guayaquil es un software que ayuda a la
                administración de comunidades, acercando a residentes y
                administradores en un solo lugar.
              </h6>
            </div>
            <div className="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-4 p-1 mt-5 mt-lg-0 pb-5">
              <div className="main-form overflow-hidden text-center">
                <div className="form-header bg-primary text-center">
                  <p className="">
                    Gestiona todo lo relacionado a tu urbanización: Pagos, reservas
                    y más.
                  </p>
                </div>
                <div className="form-body">
                  <p className="mb-3">Déjanos tus datos para más información:</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        className={`form-control shadow-none ${ errors.name ? "is-invalid" : "" }`}
                        placeholder="Nombre completo*"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        name="email"
                        {...register("email")}
                        className={`form-control shadow-none ${ errors.email ? "is-invalid" : "" }`}
                        placeholder="Correo electrónico*"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="phone"
                        {...register("phone")}
                        className={`form-control shadow-none ${ errors.phone ? "is-invalid" : "" }`}
                        placeholder="Número de teléfono*"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="city"
                        {...register("city")}
                        className={`form-control shadow-none ${ errors.city ? "is-invalid" : "" }`}
                        placeholder="Ciudad*"
                      />
                    </div>
                    <div className="mb-3">
                      <select
                      className={`form-control shadow-none ${ errors.contactVia ? "is-invalid" : "" }`} 
                      name="contactVia" {...register("contactVia")}>
                        <option>Como desea ser contactado?</option>
                        <option value="WhatsApp">WhatsApp</option>
                        <option value="Telefono">Teléfono</option>
                        <option value="Mail">Mail</option>
                      </select>
                    </div>
                    <div className="mt-4 text-end">
                      <button type="submit" className="btn submit-btn fs-bold">ENVIAR</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-5">
            <div className="card success-card">
              <div className="card-header text-center bg-primary">
                <h2 className="fs-extraBold">iTu registro fue exitoso!</h2>
              </div>
              <div className="card-body px-4 px-md-5 py-4">
                <div className="text-center text-dark-gray mx-4 mx-md-5">
                  <h5 className="fs-regular success-content">Bienvenido a una nueva forma de gestionar todo lo relacionado a tu urbanización.</h5>
                </div>
                <hr className="my-3" />
                <div className="display-flex">
                  <img src={logoImg} className="success-logo me-3" alt="logo-img" />
                  <div>
                    <h5 className="success-title1 fs-extraBold">Te acerca a</h5>
                    <h5 className="success-title2 fs-extraBold">tu comunidad</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Section1;
