import React from "react";
import casaImg from "../assets/images/casa.png";
import leafeImg from "../assets/images/leafe.png";
import hireFront1 from "../assets/images/hire/hire-front-1.png";
import hireBack1 from "../assets/images/hire/hire-back-1.png";
import hireFront2 from "../assets/images/hire/hire-front-2.png";
import hireBack2 from "../assets/images/hire/hire-back-2.png";
import hireFront3 from "../assets/images/hire/hire-front-3.png";
import hireBack3 from "../assets/images/hire/hire-back-3.png";
import hireFront4 from "../assets/images/hire/hire-front-4.png";
import hireBack4 from "../assets/images/hire/hire-back-4.png";
import Link from "react-scroll/modules/components/Link";

function Section2() {
  const hireData = [
    { frontImg: hireFront1, backImg: hireBack1, text: '<p class="fs-5 fs-bold d-inline">$0 costo</p> por conexión mensual con Datafast' },
    { frontImg: hireFront2, backImg: hireBack2, text: '<p class="fs-5 fs-bold">$0 de comisión</p> por pagos con tarjetas de crédito' },
    { frontImg: hireFront3, backImg: hireBack3, text: 'Respaldo nacional e internacional' },
    { frontImg: hireFront4, backImg: hireBack4, text: 'Acepta todas las formas de pago del mercado' },
  ];
  return (
    <section className="section_2">
      <div className="row m-0 align-items-center justify-content-around position-relative">
        <div className="col-5 col-md-6 col-xl-5 mt-5">
          <img src={casaImg} className="casa-img" alt="casa" />
          <img src={leafeImg} alt="leafeImg" className="leafe-img" />
        </div>
        <div className="col-7 col-md-6 col-xl-4 py-5 position-relative">
          <p className="text-light-gray casa-text">
            Además de optimizar la gestión diaria, la plataforma digital <b className="fs-extraBold"> TRIBU </b>
            de Banco Guayaquil, conecta directamente a administradores con
            residentes. Te permite agilizar tus pagos y facilita la comunicación
            con tu comunidad.
          </p>
          <p className="mt-3 text-light-gray casa-text">
            Para contratarla, solo debes tener una cuenta de ahorros o corriente
            en Banco Guayaquil.
          </p>
          <Link to="contáctanos" spy={true}><button className="btn mt-3">CONTÁCTANOS</button></Link>
        </div>
      </div>
      <div className="row justify-content-center bg-primary m-0 py-5">
        <div className="col-12 text-center mt-4 mb-5">
          <h2 className="fs-regular">¿POR QUÉ CONTRATARLA?</h2>
        </div>
        {hireData.map((e, i) => {
          return (
            <div className="col-6 col-lg-3 mb-5 mb-lg-0 text-center display-flex flex-column" key={i}>
              <div className="hire-img mb-3">
                <img src={e.frontImg} className="hire-front-img" alt="" />
                <img src={e.backImg} className="hire-back-img" alt="" />
              </div>
              <h6 className="col-12 col-md-9" dangerouslySetInnerHTML={{ __html: e.text }}></h6>
            </div>
          )
        })}
      </div>
    </section>
  );
}

export default Section2;
