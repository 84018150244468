import React, { useState } from "react";
import logoImg from "../assets/images/main-logo.png";
import navIcon from "../assets/images/icons/nav-icon.png";
import Link from "react-scroll/modules/components/Link";

function Header() {
  const [isMenu, setIsMenu] = useState(false);
  const handleMenu = () => {
    setIsMenu(!isMenu);
  };

  return (
    <div className="main-header overflow-hidden bg-primary" id="acceder">
      <div className="row m-0 align-items-center justify-content-between position-relative">
        <div className="col-3 p-0 my-3 text-center">
          <img src={logoImg} className="logo-img" alt="logo-img" />
        </div>
        <div className="col-9 p-0 web-navbar">
          <div className="nav-title display-flex justify-content-end">
            <h6 className="title-btn">
            <a href='https://www.bancoguayaquil.com/para-empresas/cuenta-corriente-empresarial/' target='_blank' rel="noreferrer">
            ¿QUIERES ABRIR UNA CUENTA?
              </a>
            </h6>
          </div>
          <hr className="" />
          <div className="nav-bar display-flex justify-content-end">
            <Link
              activeClass="active"
              to="beneficios"
              spy={true}
              className="nav-item mb-2"
            >
              BENEFICIOS
            </Link>
            <Link
              activeClass="active"
              to="costos"
              spy={true}
              className="nav-item mb-2"
            >
              COSTOS
            </Link>
            <Link
              activeClass="active"
              to="contáctanos"
              spy={true}
              className="nav-item mb-2"
            >
              CONTÁCTANOS
            </Link>
            <a 
              className="nav-item mb-2 active"
              target={"_blank"}
              rel="noreferrer"
              href="https://clientes.tribu.com.ec/main.php?prm0=0x0010">
              ACCEDER
              </a>
          </div>
        </div>
        <div className="col-9 p-0 mobile-navbar">
          <hr className="my-2 mt-3 my-sm-3" />
          <div className="display-flex justify-content-end mb-2">
            <h6 className="title-btn title-hide">
              <a href='https://www.bancoguayaquil.com/para-empresas/cuenta-corriente-empresarial/' target='_blank' rel="noreferrer">
                ¿QUIERES ABRIR UNA CUENTA?
              </a>
            </h6>
            <p className="nav-item active"><a 
              className="nav-item mb-2 active"
              target={"_blank"}
              rel="noreferrer"
              href="https://clientes.tribu.com.ec/main.php?prm0=0x0010">ACCEDER</a></p>
            <button className="btn nav-btn" onClick={handleMenu}>
              <img src={navIcon} className="nav-icon" alt="nav-icon" />
            </button>
          </div>
        </div>
        <div className={`navbar-menu col-12 ${isMenu && "show"}`}>
          <Link activeClass="active" to="beneficios" spy={true}>
            <p className="nav-item mb-2">BENEFICIOS</p>
          </Link>
          <Link activeClass="active" to="costos" spy={true}>
            <p className="nav-item mb-2">COSTOS</p>
          </Link>
          <Link activeClass="active" to="contáctanos" spy={true}>
            <p className="nav-item mb-2">CONTÁCTANOS</p>
          </Link>
          <Link activeClass="active" to="abrirCuenta" spy={true}>
            <p className="nav-item mb-2 m-0 p-0"><a 
              className="nav-item m-0 p-0  mb-2"
              target={"_blank"}
              rel="noreferrer"
              href="https://www.bancoguayaquil.com/para-empresas/cuenta-corriente-empresarial/">¿QUIERES ABRIR UNA CUENTA?</a></p>
          </Link>
         
        </div>
      </div>
    </div>
  );
}

export default Header;
