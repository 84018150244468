import React from "react";
import "../assets/css/global.scss";

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return <main>{children}</main>;
  }
}

export default Layout;
