import axios from "axios";
axios.defaults.baseURL = 'http://localhost:3000/api';

export const createContactUs = async (data) => {
  return await axios.post("/contact", data);
}

export const sendToLeadbox = async (data) => {
  return await axios.post("https://leadbox.ec/api/callback/landing", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}